import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00B8A9', // soothing green/teal
    },
    secondary: {
      main: '#FFC046', // orange
    },
    error: {
      main: '#f44336',
    },
    background: {
      default: '#DFFF00', // lime green
    },
    text: {
      primary: '#AE00FF', // neon purple
      secondary: '#00B8A9', // green/teal
    }
  },
  typography: {
    fontFamily: 'Press Start 2P, Arial', // general font
    h1: {
      fontFamily: 'Pacifico, Arial',
      color: '#AE00FF', // neon purple for headers
    },
    h2: {
      fontFamily: 'Pacifico, Arial',
      color: '#AE00FF',
    },
    h3: {
      fontFamily: 'Press Start 2P, Arial',
    },
    // ... other headers or text variants
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#00B8A9', 
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#fff',
          '&.Mui-selected': {
            color: '#fff',
          },
        },
      },
    },
  },
});

export default theme;
