import React, { useState } from 'react';

function ChallengeInstructions({ instructions, onSubmit }) {
  const [answers, setAnswers] = useState([]);

  // Extracting placeholders
  const placeholders = instructions.match(/{{(.*?)}}/g) || [];
  const placeholderNames = placeholders.map(ph => ph.slice(2, -2));

  const handleChange = (e, index) => {
    const newAnswers = [...answers];
    newAnswers[index] = e.target.value;
    setAnswers(newAnswers);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(answers);
  };

  // Split text and placeholders alternately
  const parseTextAndInputs = () => {
    const parts = instructions.split(/{{.*?}}/);
    const elements = [];

    parts.forEach((part, index) => {
      elements.push(<span key={index}>{part}</span>); // Add text part

      // If there is a placeholder to add
      if (index < placeholders.length) {
        elements.push(
          <input
            key={placeholders[index]}
            type="text"
            placeholder={placeholderNames[index]}
            value={answers[index] || ""}
            onChange={(e) => handleChange(e, index)}
            required
            style={{fontStyle: 'italic'}}
          />
        );
      }
    });
    return elements;
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>{parseTextAndInputs()}</p>
      <button type="submit">Submit</button>
    </form>
  );
}

export default ChallengeInstructions;
