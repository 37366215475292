import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, CircularProgress, Snackbar, Alert } from '@mui/material';
import { saveChallenge } from '../api/challenges';
import NewChallengeForm from '../components/NewChallengeForm';
import { supabaseClient } from '../services/supabase-client';
import { useAuth } from '../hooks/Auth';

function NewChallengePage() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [challengeCount, setChallengeCount] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchChallengesCount = async () => {
      try {
        const { data, count } = await supabaseClient
          .from('challenges')
          .select('id', { count: 'exact' })
          .eq('created_by', user.id); 
        setChallengeCount(count);
      } catch (e) {
        setError(`Failed to fetch challenges: ${e.message}`);
        setSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    };
    if (user) fetchChallengesCount();
  }, [user]);

  async function submitChallenge(challenge) {
    setIsSubmitting(true);
    try {
        const createdChallenge = await saveChallenge(challenge);
        navigate(`/challenge/${createdChallenge.id}`);
    } catch (err) {
        setError(err.message);
        setSnackbarOpen(true);
    }
    setIsSubmitting(false);
}


  function cancelHandler() {
    navigate('/challenges');
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Stack direction="column" spacing={2} alignItems="center" sx={{ mt: 4 }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {challengeCount >= 20 ? (
            <p>You have reached the maximum of 20 hosted challenges.</p>
          ) : (
            <NewChallengeForm
              onCancel={cancelHandler}
              onSubmit={submitChallenge}
              submitting={isSubmitting}
            />
          )}
          <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={6000} 
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        </>
      )}
    </Stack>
  );
}

export default NewChallengePage;
