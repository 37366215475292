import { supabaseClient } from '../services/supabase-client';

export async function getChallenges() {
    const { data, error } = await supabaseClient
        .from('challenges')
        .select('*');
    
    if (error) {
        throw new Error(error.message);
    }

    return data;
}

export async function getChallenge(id) {
    const { data, error } = await supabaseClient
        .from('challenges')
        .select('*')
        .eq('id', id)
        .single();
    
    if (error) {
        throw new Error(error.message);
    }

    return data;
}

export async function getChallengesForCreator(userId) {
    const { data, error } = await supabaseClient
        .from('challenges')
        .select('*')
        .eq('created_by', userId);
    
    if (error) {
        throw new Error(error.message);
    }

    return data;
}

export async function saveChallenge(challenge) {
    if (challenge.instructions.trim().length < 10) {
        throw new Error('Invalid input data provided.');
    }

    const { data, error } = await supabaseClient
    .from('challenges')
    .insert({
        'title': challenge.title,
        'instructions': challenge.instructions,
        'style_text': challenge.styleText,
        'created_at': new Date().toLocaleDateString("sv-SE"),
    })
    .select('*'); 


    if (error) {
        throw new Error(error.message);
    }

    if (data && data.length > 0) {
        return data[0];
    } else {
        throw new Error('Unknown error: challenge not created');
    }
}


export async function getUserSubmission(userId, challengeId) {
    try {
        const { data, error } = await supabaseClient
            .from('submissions')
            .select('*')
            .eq('creator', userId)
            .eq('challenge_id', challengeId)
            .order('started_at', { ascending: true })
            .limit(1); // Limiting to one to retrieve the earliest submission if it exists.

        if (error) {
            throw error;
        }

        // Return the first submission or null if none exists.
        return data.length ? data[0] : null;
    } catch (error) {
        console.error('Error fetching user’s submission: ', error);
        return null;
    }
}

export async function submitToChallenge(userId, challengeId) {
    try {
        // Assume your submission object needs userId and challengeId at least
        const newSubmission = {
            'creator': userId,
            'challenge_id': challengeId,
        };
        const { data, error } = await supabaseClient
            .from('submissions')
            .insert([newSubmission])
            .select();
        if (error) throw error;
        console.log("data is", data);
        return data;
    } catch (error) {
        console.error("Error creating submission: ", error);
        throw error;
    }
}
