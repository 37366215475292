import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
} from '@mui/material';

function NewChallengeForm({ onCancel, onSubmit, submitting }) {
  const [title, setTitle] = useState('');
  const [instructions, setInstructions] = useState('');
  const [styleText, setStyleText] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const validateField = (fieldName, value) => {
    let error = "";
    if (fieldName === 'title' && !value.trim()) error = 'Title is required';
    if (fieldName === 'instructions' && value.trim().length < 10) error = 'Instructions must be at least 10 characters long';

    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error }));
  };

  const handleBlur = (field) => {
    setTouched((prevTouched) => ({ ...prevTouched, [field]: true }));
    validateField(field, field === 'title' ? title : field === 'instructions' ? instructions : styleText);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate all fields on form submission
    validateField('title', title);
    validateField('instructions', instructions);
    validateField('styleText', styleText);

    if (!errors.title && !errors.instructions && !errors.styleText) {
      const localUserDate = new Date().toLocaleDateString();
      const challengeObject = {
        title,
        instructions,
        styleText,
        createdAt: new Date(localUserDate).toISOString(),
      };

      onSubmit(challengeObject);
      setTitle('');
      setInstructions('');
      setStyleText('');
      setErrors({});
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Create New Challenge
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal" error={!!errors.title && touched.title} required>
          <TextField
            id="title"
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={() => handleBlur('title')}
            error={!!errors.title && touched.title}
            helperText={(touched.title && errors.title) || ' '}
          />
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.instructions && touched.instructions} required>
          <TextField
            id="instructions"
            label="Instructions"
            multiline
            rows={4}
            variant="outlined"
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
            onBlur={() => handleBlur('instructions')}
            error={!!errors.instructions && touched.instructions}
            helperText={(touched.instructions && errors.instructions) || "Use {{placeholder}} to indicate variable parts."}
          />
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.styleText && touched.styleText} required>
          <TextField
            id="styleText"
            label="Style Text"
            multiline
            rows={4}
            variant="outlined"
            value={styleText}
            onChange={(e) => setStyleText(e.target.value)}
            onBlur={() => handleBlur('styleText')}
            error={!!errors.styleText && touched.styleText}
            helperText={(touched.styleText && errors.styleText) || "This text will be used for stylizing AI art."}
          />
        </FormControl>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            {submitting ? 'Submitting...' : 'Create Challenge'}
          </Button>
          <Button
            variant="outlined"
            onClick={onCancel}
            disabled={submitting}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Container>
  );
}

export default NewChallengeForm;
