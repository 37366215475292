import React, { useEffect, useState } from 'react';
import { getAttempts } from '../api/submissions';
import { CircularProgress, Typography } from '@mui/material';
import AttemptCard from './AttemptCard';

function AttemptCarousel({ challengeTitle, submissionId }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [attempts, setAttempts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const attemptsData = await getAttempts(submissionId);
                setAttempts(attemptsData);
            } catch (err) {
                setError(`Failed to fetch data: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [submissionId]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <div>
            <Typography>{challengeTitle}</Typography>
            <div style={{ display: 'flex', overflowX: 'scroll', gap: '16px' }}>
                {attempts.slice(0).reverse().map((attempt) => (
                    <AttemptCard key={attempt.id} attempt={attempt} />
                ))}
            </div> 
        </div>
        
    );
}

export default AttemptCarousel;
