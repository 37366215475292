import React, { useEffect, useState } from 'react';
import { getChallenges } from '../api/challenges';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { CircularProgress, Stack, Typography } from '@mui/material';
import Challenges from '../components/Challenges';  

function ChallengesPage() {
  const [error, setError] = useState();
  const [challenges, setChallenges] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function loadChallenges() {
      setIsLoading(true);
      try {
        const challengesData = await getChallenges();
        setChallenges(challengesData);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    }

    loadChallenges();
  }, []);

  return (
    <Stack spacing={4} sx={{ mt: 4, position: 'relative', minHeight: '100vh', alignItems: 'center' }}>
      <Typography variant="h4">All Challenges</Typography>
      {isLoading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {!error && challenges && <Challenges challenges={challenges} />}
      <Link to="/challenges/new" style={{ position: 'fixed', right: 16, bottom: 16, textDecoration: 'none' }}>
        <Fab color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </Link>
    </Stack>
  );
}

export default ChallengesPage;
