import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

function Challenges({ challenges }) {
  return (
    <List>
      {challenges.map((challenge) => (
        <ListItem
          key={challenge.id}
          component={RouterLink}
          to={'/challenge/' + challenge.id.toString()}
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            width: '100%',
            borderRadius: 1,
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
        >
          <ListItemText
            primary={
              <Typography variant="h6" color="textPrimary">
                {challenge.title}
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="textSecondary">
                {challenge.instructions}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}

export default Challenges;
