import React, { useEffect, useState } from 'react';
import { getSubmission, getAttempts, createAttempt } from '../api/submissions';
import { useParams } from 'react-router-dom';
import { CircularProgress, Typography, Button } from '@mui/material';
import { useAuth } from '../hooks/Auth';
import ChallengeInstructions from '../components/ChallengeInstructions';
import { getChallenge } from '../api/challenges';
import Lottie from 'lottie-react';
import magicBallAnimation from '../assets/animations/magic-ball.json'; 
import rotatingCirclesAnimation from '../assets/animations/rotating-circles-loader.json'; 
import AttemptCarousel from '../components/AttemptCarousel';

function SubmissionDetailsPage() {
    const [submission, setSubmission] = useState(null);
    const [challenge, setChallenge] = useState(null);
    const [attempts, setAttempts] = useState([]);
    const [allowNewAttempt, setAllowNewAttempt] = useState(false);
    const [error, setError] = useState(null);
    const [showInstructions, setShowInstructions] = useState(false);
    const [generatedImageUrl, setGeneratedImageUrl] = useState(null);
    const { user } = useAuth();
    const { submissionId, challengeId } = useParams();
    const [loadingPhase, setLoadingPhase] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const challengeData = await getChallenge(challengeId);
                setChallenge(challengeData);
                const submissionData = await getSubmission(user.id, challengeId);
                setSubmission(submissionData);
                const attemptsData = await getAttempts(submissionId);
                setAttempts(attemptsData);

                const today = new Date().toLocaleDateString('en-CA');
                const lastAttemptDate = attemptsData[0]?.utc_date?.split('T')[0];
                setAllowNewAttempt(today !== lastAttemptDate);
            } catch (err) {
                setError(`Failed to fetch data: ${err.message}`);
            } finally {
                setLoadingPhase(null);
            }
        };

        fetchData();
    }, [submissionId, challengeId, user.id]);

    const handlePlayToday = () => {
        setShowInstructions(true);
    };

    const formatAttemptIntoString = (instructions, answers) => {
        let formattedText = instructions;
        answers.forEach(answer => {
            formattedText = formattedText.replace(/{{(.*?)}}/, answer);
        });
        return formattedText;
    };

    const handleAttemptSubmit = async (answer) => {
        try {
            
            setShowInstructions(false);
            setAllowNewAttempt(false);
            const formattedText = formatAttemptIntoString(challenge.instructions, answer);

            // Generating Prompt with GPT-4
            setLoadingPhase('🔮 Peering into the digital abyss...');
            const responseGPT = await fetch("/api/generate_prompt", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    text: formattedText,
                    style: challenge.style_text
                })
            });
            const dataGPT = await responseGPT.json();
            const generatedPrompt = dataGPT.result;
            
            // Generating Image with DALL·E
            setLoadingPhase('🎨 Concocting pixels and vectors...');
            const responseDALLE = await fetch("/api/generate_image", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    prompt: generatedPrompt
                })
            });
            const dataDALLE = await responseDALLE.json();
            const generatedImageUrl = dataDALLE.result;
            
            setLoadingPhase('💾 Saving your progress...');
            // const newAttempt = await createAttempt(challengeId, submissionId, answer, generatedImageUrl, generatedPrompt);
            
            // setAttempts([newAttempt, ...attempts]);
            setGeneratedImageUrl(generatedImageUrl);
            setLoadingPhase(null);
    
        } catch (err) {
            setError(`An error occurred: ${err.message}`);
        }
    };    

    if (loadingPhase) {
        return (
            <div style={{ textAlign: 'center' }}>
                <Lottie animationData={loadingPhase.includes('🔮') ? magicBallAnimation : rotatingCirclesAnimation} style={{ height: 120, width: 120 }} />
                <Typography>{loadingPhase}</Typography>
            </div>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <div>
            <Typography variant="h4">{challenge?.title}</Typography>
            <Typography variant="body1">{submission?.started_at}</Typography>

            {allowNewAttempt ? (
                <Button variant="contained" color="primary" onClick={handlePlayToday}>
                    Play for today
                </Button>
            ) : (
                <Typography>You've already attempted today. Come back tomorrow!</Typography>
            )}

            {showInstructions && challenge && (
                <ChallengeInstructions
                    instructions={challenge.instructions}
                    onSubmit={handleAttemptSubmit}
                />
            )}

            {generatedImageUrl && (
                <div>
                    <Typography variant="h5">Generated Image:</Typography>
                    <img src={generatedImageUrl} alt="Generated" style={{ height: '50vh' }} />
                </div>
            )}

            {!allowNewAttempt && challenge && submission && (
                <>
                    <Typography variant="h5">Previous Attempts:</Typography>
                    <AttemptCarousel challengeTitle={challenge?.title} submissionId={submission?.id} />
                </>
            )}

            
        </div>
    );
}

export default SubmissionDetailsPage;
