import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getChallenge, getUserSubmission, submitToChallenge } from '../api/challenges';
import { CircularProgress, Typography, Button, Card, CardMedia, CardContent } from '@mui/material';
import { useAuth } from '../hooks/Auth';  

function ChallengeDetailsPage() {
  const [error, setError] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const [submission, setSubmission] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadChallenge() {
      setIsLoading(true);
      try {
        const challengeData = await getChallenge(params.challengeId);
        setChallenge(challengeData);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    }
    loadChallenge();
  }, [params.challengeId]);

  useEffect(() => {
    async function loadSubmission() {
      try {
        const submissionData = await getUserSubmission(user.id, params.challengeId);
        // Only setSubmission if submissionData is not null to avoid unnecessary re-renders
        if (submissionData) {
          setSubmission(submissionData);
        }
      } catch (err) {
        // Log and/or handle errors in fetching submission in a user-friendly way
        console.error("Error fetching submission: ", err);
        setError('Error fetching submission. Please try again later.'); // Optionally set error state
      }
    }
    if (user) {
      loadSubmission();
    }
  }, [user, params.challengeId]);

  const handleCreateAndNavigateToSubmission = async () => {
    try {
      const newSubmission = await submitToChallenge(user.id, challenge.id);
      if (newSubmission && newSubmission[0]) {
        navigate(`/challenge/${challenge.id}/${newSubmission[0].id}`);
      } else {
        throw new Error("Submission object is null");
      }
    } catch (err) {
      console.error("Error creating submission: ", err);
    }
  };
  

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : challenge ? (
        <Card>
          <CardMedia 
            component="img"
            height="140"
            image={challenge.image_url}
            alt={challenge.title}
          />
          <CardContent>
            <Typography variant="h4">{challenge.title}</Typography>
            <Typography variant="body2" color="text.secondary">
              {`Created by: ${challenge.created_by} on ${new Date(challenge.created_at).toLocaleDateString()}`}
            </Typography>
            <Typography variant="body1">{challenge.instructions}</Typography>
          </CardContent>
        </Card>
      ) : null}
      
      {(challenge && submission) ? (
        <Link to={`/challenge/${challenge.id}/${submission.id}`}>
          <Button variant="contained" color="primary">My Submission</Button>
        </Link>
      ) : (
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleCreateAndNavigateToSubmission}
        >
          Submit to Challenge
        </Button>
      )}
    </div>
  );
}

export default ChallengeDetailsPage;
