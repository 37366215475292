import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/Auth';
import { getChallengesForCreator } from '../api/challenges';
import { CircularProgress, Stack, Typography } from '@mui/material';
import Challenges from '../components/Challenges';  

function ProfilePage() {
    const { user } = useAuth();
    const [challenges, setChallenges] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        async function loadChallenges() {
          setIsLoading(true);
          try {
            const challengesData = await getChallengesForCreator(user.id);
            setChallenges({[user.id]: challengesData});
          } catch (err) {
            setError(err.message);
          }
          setIsLoading(false);
        }
    
        loadChallenges();
      }, []);

    return (
        <div>
            <p>Email: {user?.email}</p>
            <small>Joined: {user && new Date(user.created_at).toLocaleDateString()}</small>
            <Stack spacing={4} sx={{ mt: 4, position: 'relative', minHeight: '100vh', alignItems: 'center' }}>
              <Typography variant="h4">Challenges I host</Typography>
              {isLoading && <CircularProgress />}
              {challenges && <Challenges challenges={challenges[user.id]} />}
            </Stack>
        </div>
    );
}

export default ProfilePage;
