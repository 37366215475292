import React from 'react';
import { AuthProvider } from './hooks/Auth';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import ChallengesPage from './pages/ChallengesPage';
import ProfilePage from './pages/ProfilePage';
import ProtectedRoute from './components/ProtectedRoute';
import RootLayout from './components/RootLayout';
import ConfigureChallengePage from './pages/ConfigureChallengePage';
import ChallengeDetailsPage from './pages/ChallengeDetailsPage';
import SubmissionDetailsPage from './pages/SubmissionDetailsPage';
import ProgressPage from './pages/ProgressPage';
import LandingPage from './pages/LandingPage';

const App = () => (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <AuthProvider>
        <RootLayout>
          <Routes>
            <Route path="/challenges" element={<ProtectedRoute><ChallengesPage /></ProtectedRoute>} />
            <Route path="/challenges/new" element={<ProtectedRoute><ConfigureChallengePage /></ProtectedRoute>} />
            <Route path="/challenge/:challengeId" element={<ProtectedRoute><ChallengeDetailsPage /></ProtectedRoute>} />
            <Route path="/challenge/:challengeId/:submissionId" element={<ProtectedRoute><SubmissionDetailsPage /></ProtectedRoute>} />
            <Route path="/planet" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
            <Route path="/progress" element={<ProtectedRoute><ProgressPage /></ProtectedRoute>} />
            <Route path="/welcome" element={<LandingPage />} />
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </RootLayout>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>
)

export default App;
