import React from 'react';
import { useAuth } from '../hooks/Auth';
import { Auth } from '@supabase/auth-ui-react';
import { supabaseClient } from '../services/supabase-client';
import '../assets/styles/LandingPage.css';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

function LandingPage() {
    const { user } = useAuth();

    return (
        <div className="landing-page">
            {/* Hero Section */}
            <section className="hero-section">
                <div className="hero-background">
                    {/* Background Graphic Placeholder */}
                </div>
                <div className="hero-content">
                    <h1>Welcome to Mangrove</h1>
                    <h2>Cultivate your growth.</h2>
                    <Button 
                        variant="contained" 
                        className="begin-journey-btn" 
                        onClick={() => window.location.href = 'https://h4wwlpfyvhm.typeform.com/to/G0r760pe'}>
                        Begin Your Journey
                    </Button>
                </div>
            </section>

            {/* Features Section */}
            <section className="features-section">
                <div className="feature-card">
                    {/* Icon Placeholder */}
                    <p>🌱 Grow and learn together in a virtual community garden.</p>
                </div>
                <div className="feature-card">
                    {/* Icon Placeholder */}
                    <p>🧘 Minimal interface with 3D life via daily text prompt.</p>
                </div>
                <div className="feature-card">
                    {/* Icon Placeholder */}
                    <p>🌐 Dive into a weekly digest of community insights.</p>
                </div>
                <div className="feature-card">
                    {/* Icon Placeholder */}
                    <p>🎨 Keep track and show off your accomplishments in style.</p>
                </div>
            </section>

            {/* About Mangrove Section */}
            <section className="about-mangrove-section">
                <div className="about-text">
                    <p>Learn more about how Mangrove is reshaping the way we connect, learn, and grow together.</p>
                </div>
                <div className="about-graphic">
                    {/* Graphic Placeholder */}
                </div>
            </section>

            {/* Testimonials/Success Stories */}
            <section className="testimonials-section">
                {/* Testimonials Placeholder */}
                <p>"Mangrove has changed the way I approach my daily routine and connections..." - A Happy User</p>
            </section>

            {/* Footer */}
            <footer className="landing-footer">
                <div className="social-media-links">
                    {/* Social Media Icons Placeholder */}
                </div>
                <a href="/privacy-policy.html">Privacy Policy</a>
            </footer>
        </div>
    );
}

export default LandingPage;
