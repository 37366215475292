import { supabaseClient } from '../services/supabase-client';

export async function getSubmissions(userId) {
  try {
      // Fetch submissions from the database where the creator is the given user.
      // Optionally: Join with the challenges table to get relevant challenge details.
      const { data: submissionsData, error: submissionsError } = await supabaseClient
        .from('submissions')
        .select('*')
        .eq('creator', userId);

      if (submissionsError) {
          throw submissionsError;
      }

      return submissionsData
  } catch (error) {
      console.error("Error fetching submissions: ", error);
      throw error;
  }
}

export async function getSubmission(userId, challengeId) {
    try {
        const { data, error } = await supabaseClient
          .from('submissions')
          .select('*')
          .eq('creator', userId)
          .eq('challenge_id', challengeId)
          .single();
    
        if (error) {
          throw error;
        }
    
        return data;
      } catch (error) {
        console.error("Error fetching submission: ", error);
        throw error;
      }
}

export async function getAttempts(submissionId) {
    try {
        const { data, error } = await supabaseClient
          .from('attempts')
          .select('*')
          .eq('submission_id', submissionId)
          .order('utc_date', { ascending: false });
    
        if (error) {
          throw error;
        }
    
        return data;
      } catch (error) {
        console.error("Error fetching attempts: ", error);
        throw error;
      }
}

export async function saveAttempt(challengeId, submissionId, answer, generatedImageUrl) {
    try {
        const { data, error } = await supabaseClient
          .from('attempts')
          .insert([{ 
            submission_id: submissionId, 
            challenge_id: challengeId, 
            answer: answer,
            image_url: generatedImageUrl,
          }])
          .single();
    
        if (error) {
          throw error;
        }
    
        return data;
      } catch (error) {
        console.error("Error creating attempt: ", error);
        throw error;
      }
}
