import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/Auth';
import { getSubmissions, getAttempts } from '../api/submissions';
import { CircularProgress, Typography } from '@mui/material';
import AttemptCarousel from '../components/AttemptCarousel.jsx';

function ProgressPage() {
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submissions, setSubmissions] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const submissionsData = await getSubmissions(user.id);
                setSubmissions(submissionsData);
            } catch (err) {
                setError(`Failed to fetch data: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [user.id]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <div>
            <Typography variant="h4">My Progress</Typography>

            {submissions.map((submission) => (
                <div key={submission.id}>
                    <AttemptCarousel challengeTitle={submission.challengeTitle} submissionId={submission.id} />
                </div>
            ))}
        </div>
    );
}

export default ProgressPage;
