import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';

function AttemptCard({ attempt }) {
  return (
    <Card>
      <CardMedia
        component="img"
        height="140"
        image={attempt.image_url}
        alt="Generated during attempt"
      />
      <CardContent>
        <Typography variant="h6">
          {`Attempt from ${new Date(attempt.utc_date).toLocaleDateString()}`}
        </Typography>
        <Typography variant="body2">
          Answers: {attempt.answer.join(', ')}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default AttemptCard;
